import createReducer from "../createReducer";
import { getToken } from "./user";
import qs from "query-string";

import { getRawMaterialsForSelectedCategory } from "./rawMaterials";
import { removeFalsy } from "../utils";

import { MODAL_PAGE_SIZE, PAGE_SIZE } from "../constants";

export const OPEN_RAW_MATERIALS_MODAL = "RawMaterials.OPEN_RAW_MATERIALS_MODAL";
export const CLOSE_RAW_MATERIALS_MODAL = "RawMaterials.CLOSE_RAW_MATERIALS_MODAL";

export const MODAL_GET_RAW_MATERIALS_REQUEST = "RawMaterials.MODAL_GET_RAW_MATERIALS_REQUEST";
export const MODAL_GET_RAW_MATERIALS_SUCCESS = "RawMaterials.MODAL_GET_RAW_MATERIALS_SUCCESS";
export const MODAL_GET_RAW_MATERIALS_FAILURE = "RawMaterials.MODAL_GET_RAW_MATERIALS_FAILURE";

export const MODAL_GET_RAW_MATERIAL_NAMES_REQUEST = "RawMaterials.MODAL_GET_RAW_MATERIAL_NAMES_REQUEST";
export const MODAL_GET_RAW_MATERIAL_NAMES_SUCCESS = "RawMaterials.MODAL_GET_RAW_MATERIAL_NAMES_SUCCESS";
export const MODAL_GET_RAW_MATERIAL_NAMES_FAILURE = "RawMaterials.MODAL_GET_RAW_MATERIAL_NAMES_FAILURE";

export const CLEAR_RAW_MATERIALS_MODAL = "RawMaterials.CLEAR_RAW_MATERIALS_MODAL";

import { GET_RAW_MATERIALS_SUCCESS } from './rawMaterials';


export const clearRawMaterialsModal = () => ({
  type: CLEAR_RAW_MATERIALS_MODAL,
});

export const MODAL_GET_RAW_SUPPLIER_REQUEST = "RawMaterials.MODAL_GET_RAW_SUPPLIER_REQUEST";
export const MODAL_GET_RAW_SUPPLIER_SUCCESS = "RawMaterials.MODAL_GET_RAW_SUPPLIER_SUCCESS";
export const MODAL_GET_RAW_SUPPLIER_FAILURE = "RawMaterials.MODAL_GET_RAW_SUPPLIER_FAILURE";

export const MODAL_GET_RAW_PLANTPART_REQUEST = "RawMaterials.MODAL_GET_RAW_PLANTPART_REQUEST";
export const MODAL_GET_RAW_PLANTPART_SUCCESS = "RawMaterials.MODAL_GET_RAW_PLANTPART_SUCCESS";
export const MODAL_GET_RAW_PLANTPART_FAILURE = "RawMaterials.MODAL_GET_RAW_PLANTPART_FAILURE";

export const MODAL_GET_RAW_PLANTNOTE_REQUEST = "RawMaterials.MODAL_GET_RAW_PLANTNOTE_REQUEST";
export const MODAL_GET_RAW_PLANTNOTE_SUCCESS = "RawMaterials.MODAL_GET_RAW_PLANTNOTE_SUCCESS";
export const MODAL_GET_RAW_PLANTNOTE_FAILURE = "RawMaterials.MODAL_GET_RAW_PLANTNOTE_FAILURE";

export const MODAL_GET_RAW_PLANTFAMILY_REQUEST = "RawMaterials.MODAL_GET_RAW_PLANTFAMILY_REQUEST";
export const MODAL_GET_RAW_PLANTFAMILY_SUCCESS = "RawMaterials.MODAL_GET_RAW_PLANTFAMILY_SUCCESS";
export const MODAL_GET_RAW_PLANTFAMILY_FAILURE = "RawMaterials.MODAL_GET_RAW_PLANTFAMILY_FAILURE";

export const MODAL_GET_RAW_PLANTCHAKRA_REQUEST = "RawMaterials.MODAL_GET_RAW_PLANTCHAKRA_REQUEST";
export const MODAL_GET_RAW_PLANTCHAKRA_SUCCESS = "RawMaterials.MODAL_GET_RAW_PLANTCHAKRA_SUCCESS";
export const MODAL_GET_RAW_PLANTCHAKRA_FAILURE = "RawMaterials.MODAL_GET_RAW_PLANTCHAKRA_FAILURE";

export const MODAL_GET_RAW_INCINAME_REQUEST = "RawMaterials.MODAL_GET_RAW_INCINAME_REQUEST";
export const MODAL_GET_RAW_INCINAME_SUCCESS = "RawMaterials.MODAL_GET_RAW_INCINAME_SUCCESS";
export const MODAL_GET_RAW_INCINAME_FAILURE = "RawMaterials.MODAL_GET_RAW_INCINAME_FAILURE";

export const MODAL_GET_RAW_CATEGORY_REQUEST = "RawMaterials.MODAL_GET_RAW_CATEGORY_REQUEST";
export const MODAL_GET_RAW_CATEGORY_SUCCESS = "RawMaterials.MODAL_GET_RAW_CATEGORY_SUCCESS";
export const MODAL_GET_RAW_CATEGORY_FAILURE = "RawMaterials.MODAL_GET_RAW_CATEGORY_FAILURE";

export const MODAL_GET_RAW_WARNING_REQUEST = "RawMaterials.MODAL_GET_RAW_WARNING_REQUEST";
export const MODAL_GET_RAW_WARNING_SUCCESS = "RawMaterials.MODAL_GET_RAW_WARNING_SUCCESS";
export const MODAL_GET_RAW_WARNING_FAILURE = "RawMaterials.MODAL_GET_RAW_WARNING_FAILURE";

export const MODAL_GET_THERAPEUTIC_PROPERTY_REQUEST = "RawMaterials.MODAL_GET_THERAPEUTIC_PROPERTY_REQUEST";
export const MODAL_GET_THERAPEUTIC_PROPERTY_SUCCESS = "RawMaterials.MODAL_GET_THERAPEUTIC_PROPERTY_SUCCESS";
export const MODAL_GET_THERAPEUTIC_PROPERTY_FAILURE = "RawMaterials.MODAL_GET_THERAPEUTIC_PROPERTY_FAILURE";

export const MODAL_GET_THERAPEUTIC_USE_REQUEST = "RawMaterials.MODAL_GET_THERAPEUTIC_USE_REQUEST";
export const MODAL_GET_THERAPEUTIC_USE_SUCCESS = "RawMaterials.MODAL_GET_THERAPEUTIC_USE_SUCCESS";
export const MODAL_GET_THERAPEUTIC_USE_FAILURE = "RawMaterials.MODAL_GET_THERAPEUTIC_USE_FAILURE";


export const filterRawMaterials =
  (filters = {}, url = null) =>
  (dispatch, getState, { fetch }) => {
    dispatch({ type: MODAL_GET_RAW_MATERIALS_REQUEST, filters });

    const { token } = dispatch(getToken());
      const { only_mine } = getState().rawMaterials;
      console.log("only_mine = ", only_mine)
    const only_gcms = true
    // If no URL is provided, construct one using filters
    const endpoint =
      url ||
      `/pands/raw-materials/?${qs.stringify({
        ...removeFalsy(filters), // Remove falsy values from filters
        only_mine,
        only_gcms
      })}`;

    console.log("filterRawMaterials: Endpoint:", endpoint);

    return fetch(endpoint, {
      method: "GET",
      token,
      success: (res) => {
        const ingredients = res.results;
        const next = res.next;
        const count = res.count;

        // Dispatch results for modalRawMaterials and rawMaterials
        dispatch({ type: MODAL_GET_RAW_MATERIALS_SUCCESS, ingredients });
        dispatch({ type: GET_RAW_MATERIALS_SUCCESS, ingredients, next });
        dispatch({ type: UPDATE_COUNT, count });
      },
      failure: (err) => {
        console.error("filterRawMaterials: Fetch error:", err);
        dispatch({ type: MODAL_GET_RAW_MATERIALS_FAILURE });
      },
    });
  };


export const getRawMaterialNames =
  () =>
  (dispatch, getState, { fetch }) => {
    const { token } = dispatch(getToken());
    const { only_mine } = getState().rawMaterials;
    dispatch({ type: MODAL_GET_RAW_MATERIAL_NAMES_REQUEST });
    let qs = "?only_mine=" + only_mine;
    return fetch(`/pands/rawmaterialnames${qs}`, {
      token,
      method: "GET",
      success: (res) => {
        const ingredients = res.details;
        dispatch({ type: MODAL_GET_RAW_MATERIAL_NAMES_SUCCESS, ingredients });
      },
      // eslint-disable-next-line node/handle-callback-err
      failure: (err) => {
        dispatch({ type: MODAL_GET_RAW_MATERIAL_NAMES_FAILURE });
      },
    });
  };

export const getSuppliersForTableAll =
  (params = {}) =>
  (dispatch, getState, { fetch }) => {
    dispatch({ type: MODAL_GET_RAW_SUPPLIER_REQUEST });
    const { token } = dispatch(getToken());
    const search = params.search ? params.search : undefined;
    const page = params.page ? params.page : 1;
    const page_size = PAGE_SIZE;
    return fetch(
      `/pands/suppliersall/?${qs.stringify({
        search,
      })}`,
      {
        method: "GET",
        token,
        success: (res) => {
          const supplier = res.results;
          const count = res.results.length;
          dispatch({ type: MODAL_GET_RAW_SUPPLIER_SUCCESS, supplier, count });
        },
        // eslint-disable-next-line node/handle-callback-err
        failure: (err) => {
          dispatch({ type: MODAL_GET_RAW_SUPPLIER_FAILURE });
        },
      }
    );
  };

export const getPlantParts =
  (params = {}) =>
  (dispatch, getState, { fetch }) => {
    dispatch({ type: MODAL_GET_RAW_PLANTPART_REQUEST });
    const { token } = dispatch(getToken());
    const search = params.search ? params.search : undefined;
    const page = params.page ? params.page : 1;
    const page_size = PAGE_SIZE;
    return fetch("/pands/plantparts/", {
      method: "GET",
      token,
      success: (res) => {
        const plantpart = res.results;
        const count = res.results.length;
        dispatch({ type: MODAL_GET_RAW_PLANTPART_SUCCESS, plantpart, count });
      },
      // eslint-disable-next-line node/handle-callback-err
      failure: (err) => {
        dispatch({ type: MODAL_GET_RAW_PLANTPART_FAILURE });
      },
    });
  };

export const getPlantFamilys =
  (params = {}) =>
  (dispatch, getState, { fetch }) => {
    dispatch({ type: MODAL_GET_RAW_PLANTFAMILY_REQUEST });
    const { token } = dispatch(getToken());
    const search = params.search ? params.search : undefined;
    const page = params.page ? params.page : 1;
    const page_size = PAGE_SIZE;
    return fetch("/pands/plantfamilys/", {
      method: "GET",
      token,
      success: (res) => {
        const plantfamily = res.results;
        const count = res.results.length;
        dispatch({
          type: MODAL_GET_RAW_PLANTFAMILY_SUCCESS,
          plantfamily,
          count,
        });
      },
      // eslint-disable-next-line node/handle-callback-err
      failure: (err) => {
        dispatch({ type: MODAL_GET_RAW_PLANTFAMILY_FAILURE });
      },
    });
  };

export const getPlantNotes =
  (params = {}) =>
  (dispatch, getState, { fetch }) => {
    dispatch({ type: MODAL_GET_RAW_PLANTNOTE_REQUEST });
    const { token } = dispatch(getToken());
    const search = params.search ? params.search : undefined;
    const page = params.page ? params.page : 1;
    const page_size = PAGE_SIZE;
    return fetch("/pands/plantnotes/", {
      method: "GET",
      token,
      success: (res) => {
        const plantnote = res.results;
        const count = res.results.length;
        dispatch({ type: MODAL_GET_RAW_PLANTNOTE_SUCCESS, plantnote, count });
      },
      // eslint-disable-next-line node/handle-callback-err
      failure: (err) => {
        dispatch({ type: MODAL_GET_RAW_PLANTNOTE_FAILURE });
      },
    });
  };

export const getPlantChakras =
  (params = {}) =>
  (dispatch, getState, { fetch }) => {
    dispatch({ type: MODAL_GET_RAW_PLANTCHAKRA_REQUEST });
    const { token } = dispatch(getToken());
    const search = params.search ? params.search : undefined;
    const page = params.page ? params.page : 1;
    const page_size = PAGE_SIZE;
    return fetch("/pands/plantchakras/", {
      method: "GET",
      token,
      success: (res) => {
        const plantchakra = res.results;
        const count = res.results.length;
        dispatch({
          type: MODAL_GET_RAW_PLANTCHAKRA_SUCCESS,
          plantchakra,
          count,
        });
      },
      // eslint-disable-next-line node/handle-callback-err
      failure: (err) => {
        dispatch({ type: MODAL_GET_RAW_PLANTCHAKRA_FAILURE });
      },
    });
  };

export const getCategories =
  (params = {}) =>
  (dispatch, getState, { fetch }) => {
    dispatch({ type: MODAL_GET_RAW_CATEGORY_REQUEST });
    const { token } = dispatch(getToken());
    const search = params.search ? params.search : undefined;
    const page = params.page ? params.page : 1;
    const page_size = PAGE_SIZE;
    const { only_mine } = getState().rawMaterials;
    const only_eo = true      
    const endpoint =
      `/pands/raw-materials/categories/?${qs.stringify({
        only_mine,
        only_eo
      })}`;
      
    return fetch(endpoint, {
      method: "GET",
      token,
        success: (res) => {
        console.log("categories = ", res)            
        const category = res;
        const count = res.length;

        dispatch({ type: MODAL_GET_RAW_CATEGORY_SUCCESS, category, count });
      },
      // eslint-disable-next-line node/handle-callback-err
      failure: (err) => {
        dispatch({ type: MODAL_GET_RAW_CATEGORY_FAILURE });
      },
    });
  };

export const getWarnings =
  (params = {}) =>
  (dispatch, getState, { fetch }) => {
    dispatch({ type: MODAL_GET_RAW_WARNING_REQUEST });
    const { token } = dispatch(getToken());
    const search = params.search ? params.search : undefined;
    const page = params.page ? params.page : 1;
    const page_size = PAGE_SIZE;
    return fetch("/pands/warnings/", {
      method: "GET",
      token,
      success: (res) => {
        const warning = res.results;
        const count = res.results.length;
        dispatch({ type: MODAL_GET_RAW_WARNING_SUCCESS, warning, count });
      },
      // eslint-disable-next-line node/handle-callback-err
      failure: (err) => {
        dispatch({ type: MODAL_GET_RAW_WARNING_FAILURE });
      },
    });
  };

export const getTherapeuticProperties =
  (params = {}) =>
  (dispatch, getState, { fetch }) => {
    dispatch({ type: MODAL_GET_THERAPEUTIC_PROPERTY_REQUEST });
    const { token } = dispatch(getToken());
    const search = params.search ? params.search : undefined;
    const page = params.page ? params.page : 1;
    const page_size = PAGE_SIZE;
    return fetch("/pands/therapeuticproperties/", {
      method: "GET",
      token,
      success: (res) => {
        const therapeuticproperty = res.results;
        const count = res.results.length;
        dispatch({
          type: MODAL_GET_THERAPEUTIC_PROPERTY_SUCCESS,
          therapeuticproperty,
          count,
        });
      },
      // eslint-disable-next-line node/handle-callback-err
      failure: (err) => {
        dispatch({ type: MODAL_GET_THERAPEUTIC_PROPERTY_FAILURE });
      },
    });
  };

export const getTherapeuticUses =
  (params = {}) =>
  (dispatch, getState, { fetch }) => {
    dispatch({ type: MODAL_GET_THERAPEUTIC_USE_REQUEST });
    const { token } = dispatch(getToken());
    const search = params.search ? params.search : undefined;
    const page = params.page ? params.page : 1;
    const page_size = PAGE_SIZE;
    return fetch("/pands/therapeuticuses/", {
      method: "GET",
      token,
      success: (res) => {
        const therapeuticuse = res.results;
        const count = res.results.length;
        dispatch({
          type: MODAL_GET_THERAPEUTIC_USE_SUCCESS,
          therapeuticuse,
          count,
        });
      },
      // eslint-disable-next-line node/handle-callback-err
      failure: (err) => {
        dispatch({ type: MODAL_GET_THERAPEUTIC_USE_FAILURE });
      },
    });
  };

export const openRawMaterialsModal = () => (dispatch, getState) => {
  dispatch({ type: OPEN_RAW_MATERIALS_MODAL });
};

export const closeRawMaterialsModal = () => (dispatch, getState) => {
  const { selectedCategory, only_mine } = getState().rawMaterials;
  dispatch(getRawMaterialsForSelectedCategory(selectedCategory, only_mine));
  dispatch({ type: CLOSE_RAW_MATERIALS_MODAL });
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  modalRawMaterials: [],
  modalRawMaterialsModal: [],
  modalRawMaterialsModalOpened: false,

  modalRawMaterialNames: {},
  modalRawMaterialNamesLoad: false,
  totalModalRawMaterialNames: 0,

  modalsuppliers: [],
  modalsuppliersLoad: false,
  totalModalsuppliers: 0,

  modalplantparts: [],
  modalplantpartsLoad: false,
  totalModalplantparts: 0,

  modalplantfamilys: [],
  modalplantfamilysLoad: false,
  totalModalplantfamilys: 0,

  modalplantnotes: [],
  modalplantnotesLoad: false,
  totalModalplantnotes: 0,

  modalplantchakras: [],
  modalplantchakrasLoad: false,
  totalModalplantchakras: 0,

  modalcategorys: [],
  modalcategorysLoad: false,
  totalModalcategorys: 0,

  modalwarnings: [],
  modalwarningsLoad: false,
  totalModalwarnings: 0,

  modaltherapeuticproperties: [],
  modaltherapeuticpropertiesLoad: false,
  totalModaltherapeuticproperties: 0,

  modaltherapeuticuses: [],
  modaltherapeuticusesLoad: false,
  totalModaltherapeuticuses: 0,
};

export default createReducer(initialState, {
  [MODAL_GET_RAW_MATERIALS_SUCCESS]: (state, { ingredients }) => {
  console.log("MODAL_GET_RAW_MATERIALS_SUCCESS: Updating modalRawMaterials with:", ingredients);
  return {
    ...state,
    modalRawMaterials: ingredients,
    loading: false,
    recipesLoad: false,
  };
},
  [MODAL_GET_RAW_MATERIAL_NAMES_REQUEST]: (state, action) => ({
    modalRawMaterialNamesLoad: true,
  }),
  [MODAL_GET_RAW_MATERIAL_NAMES_SUCCESS]: (state, { ingredients }) => ({
    modalRawMaterialNamesLoad: false,
    modalRawMaterialNames: ingredients,
  }),
  [MODAL_GET_RAW_MATERIAL_NAMES_FAILURE]: (state, action) => ({
    modalRawMaterialNamesLoad: false,
  }),

  [CLEAR_RAW_MATERIALS_MODAL]: (state, {}) => ({
    modalRawMaterials: [],
  }),
  [OPEN_RAW_MATERIALS_MODAL]: (state, action) => ({
    modalRawMaterialsModalOpened: true,
  }),
  [CLOSE_RAW_MATERIALS_MODAL]: (state, action) => ({
    modalRawMaterialsModalOpened: false,
    selectedRows: [],
    modalRawMaterials: [],
    modalRawMaterialNames: {},
    search: undefined,
  }),
  [MODAL_GET_RAW_SUPPLIER_REQUEST]: (state, action) => ({
    modalsuppliersLoad: true,
  }),
  [MODAL_GET_RAW_SUPPLIER_SUCCESS]: (state, action) => ({
    modalsuppliersLoad: false,
    modalsuppliers: action.supplier,
    totalModalsuppliers: action.count,
  }),
  [MODAL_GET_RAW_SUPPLIER_FAILURE]: (state, action) => ({
    modalsuppliersLoad: false,
  }),

  [MODAL_GET_RAW_PLANTPART_REQUEST]: (state, action) => ({
    modalplantpartsLoad: true,
  }),
  [MODAL_GET_RAW_PLANTPART_SUCCESS]: (state, action) => ({
    modalplantpartsLoad: false,
    modalplantparts: action.plantpart,
    totalModalplantparts: action.count,
  }),
  [MODAL_GET_RAW_PLANTPART_FAILURE]: (state, action) => ({
    modalplantpartsLoad: false,
  }),

  [MODAL_GET_RAW_PLANTFAMILY_REQUEST]: (state, action) => ({
    modalplantfamilysLoad: true,
  }),
  [MODAL_GET_RAW_PLANTFAMILY_SUCCESS]: (state, action) => ({
    modalplantfamilysLoad: false,
    modalplantfamilys: action.plantfamily,
    totalModalplantfamilys: action.count,
  }),
  [MODAL_GET_RAW_PLANTFAMILY_FAILURE]: (state, action) => ({
    modalplantfamilysLoad: false,
  }),

  [MODAL_GET_RAW_PLANTNOTE_REQUEST]: (state, action) => ({
    modalplantnotesLoad: true,
  }),
  [MODAL_GET_RAW_PLANTNOTE_SUCCESS]: (state, action) => ({
    modalplantnotesLoad: false,
    modalplantnotes: action.plantnote,
    totalModalplantnotes: action.count,
  }),
  [MODAL_GET_RAW_PLANTNOTE_FAILURE]: (state, action) => ({
    modalplantnotesLoad: false,
  }),

  [MODAL_GET_RAW_PLANTCHAKRA_REQUEST]: (state, action) => ({
    modalplantchakrassLoad: true,
  }),
  [MODAL_GET_RAW_PLANTCHAKRA_SUCCESS]: (state, action) => ({
    modalplantchakrasLoad: false,
    modalplantchakras: action.plantchakra,
    totalModalplantchakras: action.count,
  }),
  [MODAL_GET_RAW_PLANTCHAKRA_FAILURE]: (state, action) => ({
    modalplantchakrasLoad: false,
  }),

  [MODAL_GET_RAW_CATEGORY_REQUEST]: (state, action) => ({
    modalcategorysLoad: true,
  }),
  [MODAL_GET_RAW_CATEGORY_SUCCESS]: (state, action) => ({
    modalcategorysLoad: false,
    modalcategorys: action.category,
    totalModalcategorys: action.count,
  }),
  [MODAL_GET_RAW_CATEGORY_FAILURE]: (state, action) => ({
    modalcategorysLoad: false,
  }),

  [MODAL_GET_RAW_WARNING_REQUEST]: (state, action) => ({
    modalwarningsLoad: true,
  }),
  [MODAL_GET_RAW_WARNING_SUCCESS]: (state, action) => ({
    modalwarningsLoad: false,
    modalwarnings: action.warning,
    totalModalwarnings: action.count,
  }),
  [MODAL_GET_RAW_WARNING_FAILURE]: (state, action) => ({
    modalwarningsLoad: false,
  }),

  [MODAL_GET_THERAPEUTIC_PROPERTY_REQUEST]: (state, action) => ({
    modaltherapeuticpropertiesLoad: true,
  }),
  [MODAL_GET_THERAPEUTIC_PROPERTY_SUCCESS]: (state, action) => ({
    modaltherapeuticpropertiesLoad: false,
    modaltherapeuticproperties: action.therapeuticproperty,
    totalModaltherapeuticproperties: action.count,
  }),
  [MODAL_GET_THERAPEUTIC_PROPERTY_FAILURE]: (state, action) => ({
    modaltherapeuticpropertiesLoad: false,
  }),

  [MODAL_GET_THERAPEUTIC_USE_REQUEST]: (state, action) => ({
    modaltherapeuticusesLoad: true,
  }),
  [MODAL_GET_THERAPEUTIC_USE_SUCCESS]: (state, action) => ({
    modaltherapeuticusesLoad: false,
    modaltherapeuticuses: action.therapeuticuse,
    totalModaltherapeuticuses: action.count,
  }),
  [MODAL_GET_THERAPEUTIC_USE_FAILURE]: (state, action) => ({
    modaltherapeuticusesLoad: false,
  }),
});
